body{
  font-family: 'Rubik';
  background: #010a1f;
}

.font-v2{
  font-family: 'League Spartan', sans-serif !important;
}

.main-container::after{
  content: "";
  position: absolute;
  background: url("https://dexsport.io/img/c5bc46200cd11faaa21a.webp") no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  z-index: -1;
}

.imgBlur{
  position: absolute;
  width: -webkit-fill-available;
  -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
  filter: blur(10px);
}

.nav-item{
  margin-left: 7px !important;
}

.link-active{
  background: rgba(255, 255, 255, 0.09);
  border-radius: 15px;
  border: solid 1px rgba(255, 255, 255, 0.3);
}

.transparent-card, input, textarea, select{
  background: rgba(255, 255, 255, 0.09) !important;
  color: white !important;
}

option{
  color: black;
}

.bg-transparent-blue{
  position: relative;
  background: rgba(66, 133, 244, 0.09);
  color: white; /*rgb(66, 133, 244);*/
  cursor: pointer;
}

.transparent-card-primary{
  background: rgba(86, 75, 237, 0.09) !important;
  color: white !important;
  border-radius: 50px;
  border: solid 1px rgba(86, 75, 237, 0.3);
}

/* .transparent-card-primary::before{
  content: "";
    position: absolute;
    background: url("https://dexsport.io/img/ce552aba6210aa39fb53.webp") no-repeat;
    background-size: 100% 100%;
    bottom: 3%;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    border-radius: 50px;
} */


.bg-transparent-blue:hover{
  box-shadow: rgba(255, 193, 7, 0.3) 0px 10px 36px 0px, rgba(255, 193, 7, 0.09) 0px 0px 0px 1px;
  border-bottom: solid 1px rgb(255, 193, 7);
}

/* .users__pic::before{
  content: "";
    position: absolute;
    background: url("https://dexsport.io/img/c5bc46200cd11faaa21a.webp") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: -1;
} */

.bg-transparent-blue::before{
  content: "";
    position: absolute;
    background: url("https://dexsport.io/img/c5bc46200cd11faaa21a.webp") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: -1;
}


.left-gold{
  position: absolute;
  background: url("https://dexsport.io/img/4775d2b422ba9a4b52a1.webp") no-repeat;
  width: 132px;
  height: 133px;
  bottom: 0;
  left: 0;
  transform: scaleX(-1);
  z-index: 0 !important;
  transition: width 0.5s, height 0.5s;
}


.card:hover .left-gold{
  position: absolute;
  background: url("https://dexsport.io/img/4775d2b422ba9a4b52a1.webp") no-repeat;
  width: 173px;
  height: 145px;
  bottom: 0;
  left: 0;
}

.right-gold{
    position: absolute;
    background: url("https://dexsport.io/img/4775d2b422ba9a4b52a1.webp") no-repeat;
    width: 132px;
    height: 133px;
    bottom: 0;
    right: 0;
    z-index: -1 !important;
    transition: width 0.5s, height 0.5s;
}

.bg-transparent-blue:hover .right-gold{
  position: absolute;
  background: url("https://dexsport.io/img/4775d2b422ba9a4b52a1.webp") no-repeat;
  width: 173px;
  height: 145px;
  bottom: 0;
  right: 0;
}

.btn-warning{
  background: rgba(255, 193, 7, 0.09);
  color: rgb(255, 193, 7);
  border-color: rgb(255, 193, 7);
}

.btn-primary{
  background: rgba(0, 123, 255, 0.09);
  color: rgb(0, 123, 255);
  border-color: rgb(0, 123, 255);
}

.dt-buttons{
  display: inline-block !important;
}

.paginate_button:hover{
  background: #564bed !important;
  border-radius: 50px !important;
}

.current{
  background: #564bed !important;
  border-radius: 50px !important;
}

.imgMainDashboard{
  margin-top: 3rem;
  height: 13rem;
  transition: transform 1.5s ease-in-out;
  cursor: pointer;
}
.imgMainDashboard:hover {
  border-radius: 50%;
  box-shadow: rgba(255, 193, 7, 0.3) 0px 10px 36px 0px, rgba(255, 193, 7, 0.09) 0px 0px 0px 1px;
  border-bottom: solid 1px rgb(255, 193, 7);
  transform: rotate(360deg) !important;
}

.main-video{
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image: linear-gradient(black, transparent);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}













.hero-area .hero-image {
  text-align: center;
  position: relative;
  z-index: 0;
}

.hero-area .hero-image .main-image {
  z-index: 0;
}

.hero-area .hero-image .h2-move-1 {
  position: absolute;
  left: 42%;
  -webkit-animation: bounce 3s linear infinite;
  animation: bounce 3s linear infinite;
}

.hero-area .hero-image .h2-move-2 {
  position: absolute;
  left: 45%;
  top: 68%;
  -webkit-animation: bounce 3s linear infinite;
  animation: bounce 3s linear infinite;
}

.hero-area .hero-image .h2-move-3 {
  position: absolute;
  left: 47%;
  top: 26%;
  z-index: -1;
  -webkit-animation: bounce 4s linear infinite;
  animation: bounce 4s linear infinite;
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  z-index: 1;
  text-align: left;
}

.hero-area .hero-content h4 {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
}

.hero-area .hero-content h1 {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: #fff;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span .text-shape {
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  z-index: -1;
}

.hero-area .hero-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  margin-top: 20px;
}

.hero-area .hero-content h1 span {
  position: relative;
  z-index: 0;
  margin-left: 0.8rem;
}

#modal-root {
  position: relative;
  z-index: 999;
}
.modal{
  background-color: rgba(0,0,0,0.1);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 99999;
  backdrop-filter: blur(8px);
  transition: backdrop-filter 2s !important;
}

.modelHide{
  backdrop-filter: blur(2px) opacity(0);
}

.modelShow{
  backdrop-filter: blur(2px) opacity(1);
}
/* 
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

table tbody thead {
  display: table;
  width: 100%;
} */